<template>
  <Cond v-loading="loading">
    <el-form class="demo-form-inline" size="mini" v-model="form">
      <el-form-item label="订单免费规则">
        <el-switch
          v-model="form.ORDER_LIMIT_SWITCH"
          active-text="开启规则"
          inactive-text="关闭规则"
          :active-value="'1'"
          :inactive-value="'0'"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="订单以内免费">
        <el-input placeholder="" v-model="form.ORDER_LIMIT_NUMS"> </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="submit" type="primary" icon="el-icon-success"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </Cond>
</template>
<script>
import Cond from "./configcond.vue";
export default {
  components: {
    Cond,
  },
  data() {
    return {
      url: {
        config: "/sysadminsystem/config",
        getconfig: "/sysadminsystem/getconfig",
      },
      loading: false,
      form: {
        ORDER_LIMIT_SWITCH: '0',
        ORDER_LIMIT_NUMS: 100,
      },
    };
  },
  created() {
    this.getConfig();
  },
  methods: {
    getConfig: function () {
      this.loading = true;
      this.$rq.postAction(this.url.getconfig, this.form).then((rs) => {
        this.loading = false;
        if (rs.code == 1) {
          for (var i in rs.list) {
            this.$set(this.form, rs.list[i].key, rs.list[i].value);
          }
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    submit: function () {
      this.loading = true;
      this.$rq.postAction(this.url.config, this.form).then((rs) => {
        this.loading = false;
        if (rs.code == 1) {
          this.getConfig();
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
  },
};
</script>