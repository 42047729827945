<template>
  <Main>
    <el-card class="box-card">
      <div>
        <slot></slot>
      </div>
    </el-card>
  </Main>
</template>
<script>
import Main from "@/components/backend/common/main.vue";
export default {
  components: {
    Main,
  },
  data() {
    return {
    };
  },
};
</script>